<template>
  <head-top class="list-content">
    <div class="list">
      <div class="head-img">
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(image, index) in images" :key="index">
            <img v-lazy="image.path" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 距离活动结束时间 -->
      <div class="list-date">
        <div class="date-left">{{ list.promotionName }}</div>
        <div class="date-right">
          <div class="title">预订剩余时间：</div>
          <div class="date-remaining">
            <span class="tian">{{ tian }}天</span>
            <span class="shi">{{ shi }}</span>
            <span class="fen">{{ fen }}</span>
            <span class="miao">{{ miao }}</span>
          </div>
        </div>
      </div>
      <!-- 入住酒店列表-->
      <div class="list-item">
        <div class="list-stay">
          <div class="list-ru" @click="clickDate">
            {{ startDate }}
            <van-icon size="20" name="notes-o" />
          </div>
          <div class="list-wan">共{{ evening }}晚</div>
          <div class="list-li" @click="clickDate">
            {{ endDate }}
            <van-icon size="20" name="notes-o" />
          </div>
        </div>
        <div class="room">
          <div class="room-left">选择房型</div>
          <div class="room-right">
            <div class="right-content" @click="orderClcik">订单中心</div>
          </div>
        </div>
        <!-- 选择房型 -->
        <div class="house">
          <div
            class="hotel-item"
            v-for="(item, index) in houseList"
            :key="index"
          >
            <div class="item">
              <div
                class="item-left"
                @click="clickImg(item)"
                v-if="item.roomPicList.length > 0"
              >
                <img v-lazy="item.roomPicList[0].path" />
              </div>
              <div class="item-right">
                <div class="one">
                  <span class="title">{{ item.roomType }}</span>
                  <span class="price">￥{{ zhuanfen(item.roomPrice) }}</span>
                </div>
                <div class="tow">
                  <span class="title">{{ item.remark }}</span>
                  <span class="price"
                    >￥{{ zhuanfen(item.roomOriginalPrice) }}</span
                  >
                </div>
                <div class="three">
                  <span class="number"
                    >剩余数量：{{ item.surplusRoomNum }}</span
                  >
                  <span class="step">
                    <van-stepper
                      v-model="item.value"
                      @change="changeItem"
                      integer
                      :max="item.surplusRoomNum"
                      :min="0"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 组合套餐 -->
        <!-- <div class="package">
          <div class="combination">组合套餐</div>
        </div>

        <div class="package-item">
          <div
            class="hotel-item"
            v-for="(item, index) in packageList"
            :key="index"
          >
            <div class="item">
              <div class="item-left">
                <img v-lazy="item.urlImg" />
              </div>
              <div class="item-right">
                <div class="one">
                  <span class="title">{{ item.title }}</span>
                  <span class="price">{{ item.price }}</span>
                </div>
                <div class="tow">
                  <span class="title"></span>
                  <span class="price">{{ item.newPrice }}</span>
                </div>
                <div class="three">
                  <span class="number"></span>
                  <span class="step">
                    <van-stepper
                      v-model="item.value"
                      @change="changeItem"
                      integer
                      :min="0"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <!-- 酒店介绍 -->

        <!-- 预订人信息 -->
        <div class="reservation">
          <div class="introduce-top">
            <div class="introduce-left">预订人信息</div>
            <div class="introduce-right"></div>
          </div>
          <div class="reservation-content">
            <div class="reservation-input">
              <van-field
                v-model="form.name"
                label="姓名"
                placeholder="请输入姓名"
                @blur="inputName"
              />
            </div>
            <div class="reservation-input">
              <van-field
                v-model="form.phone"
                label="手机号"
                type="number"
                placeholder="请输入手机号"
                @blur="inputPhone"
              />
            </div>
            <div class="reservation-input">
              <van-field
                v-model="form.company"
                label="公司名称"
                placeholder="请输入公司名称"
              />
            </div>
            <div class="reservation-input">
              <van-field
                v-model="form.remark"
                label="备注"
                placeholder="请输入备注"
              />
            </div>
            <!-- <div class="reservation-input">
              <van-field
                v-model="value"
                label="开票说明"
                placeholder="请输入开票说明"
              />
            </div>-->
          </div>
        </div>
        <!-- 入住人信息 -->
        <div class="reservation" v-if="houseList.length > 0">
          <div class="introduce-top">
            <div class="introduce-left">入住人信息</div>
            <div class="introduce-right"></div>
          </div>
          <div
            class="reservation-content reservation-content-one"
            v-for="(item, index) in houseList"
            :key="index"
            v-if="item.orderRoomPeoples.length > 0"
          >
            <div class="title">{{ item.roomType }}</div>
            <div
              class="reservation-content-type"
              v-for="(v, i) in item.orderRoomPeoples"
              :key="i"
            >
              <div class="fangjian">
                <div class="reservation-p">房间{{ i + 1 }}</div>
                <div class="div-right">
                  <span v-if="!v.checked" @click="clickChecked(index, i)"
                    >添加同住人</span
                  >
                  <span v-else @click="clickChecked(index, i)">取消同住人</span>
                </div>
              </div>
              <div class="reservation-input reservation-zidingyi">
                <div class="div-left">姓名：</div>
                <div class="div-right">
                  <input
                    v-model="v.name"
                    placeholder="每间只需要填一人"
                    type="text"
                  />
                  <i class="i1"></i>
                </div>
              </div>
              <div class="reservation-input reservation-zidingyi">
                <div class="div-left">手机号：</div>
                <div class="div-right">
                  <input
                    v-model="v.phone"
                    placeholder="每间只需要填一人"
                    type="number"
                  />
                  <i class="i2"></i>
                </div>
              </div>
              <div
                class="reservation-input reservation-zidingyi"
                v-if="v.checked"
              >
                <div class="div-left">同住人：</div>
                <div class="div-right">
                  <input
                    v-model="v.roommate"
                    placeholder="每间只需要填一人"
                    type="text"
                  />
                  <i class="i1"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 预订须知 -->

        <div class="instructions" v-if="list.bookingInstructions.length > 0">
          <div class="instructions-item">
            <div class="instructions-title">预订须知</div>
            <div class="instructions-content">
              <div
                v-for="(item, index) in list.bookingInstructions"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>

        <div class="introduce">
          <div class="introduce-top">
            <div class="introduce-left">酒店介绍</div>
            <div class="introduce-right" @click="clickIntroduce">
              <van-icon :name="isIntroduce ? 'arrow-down' : 'arrow'" />
            </div>
          </div>
          <div class="introduce-content" v-if="isIntroduce">
            <div class="introduce-wenben">
              广州逸林假日酒店(广外国际学术交流中心)是按高标准建设的集国际交流论坛、豪华商务、会议培训、城市休闲为一体的学术性酒店。酒店前接广东外语外贸大学，背连白云山国家5A级风景名胜区，大面积生态公园环抱,推窗见景，珍藏一片天然风光,真正完美贴身主流享受。居尚生态饱含负离子清新空气，用自然呼吸珍藏一片窗外风景，尽情享受悠悠宁静情怀。舒适贴身的酒店服务，高校学府的人文氛围，快乐享受，休闲健身，健康生活，流连越久神韵越深。酒店具备丰富的娱乐休闲设施，设有大型露天山泉水游泳池、健身中心、含桑拿室的更衣室等设施。更可享用白云山风景区户外拓展，广东外语外贸大学体育馆的各项体育运动设施。
            </div>
            <div
              class="service"
              v-for="(item, index) in serviceList"
              :key="index"
            >
              <div class="service-icom">
                <van-icon :name="item.icom" />
              </div>
              <div class="service-content">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <!-- 服务设施 -->
        <div class="facilities">
          <div class="introduce-top">
            <div class="introduce-left">服务设施</div>
            <div class="introduce-right" @click="clickFacilities">
              <van-icon :name="isFacilities ? 'arrow-down' : 'arrow'" />
            </div>
          </div>
          <div class="introduce-content" v-if="isFacilities">
            <div
              class="facilities-item"
              v-for="(item, index) in facilitiesList"
              :key="index"
            >
              <div class="facilities-icom">
                <img :src="item.urlImg" alt />
                <div>{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 提交订单 -->
      <div class="submit">
        <div class="real-pay">
          <span class="span1">实付金额：</span>
          <span class="span2">￥{{ amountTotal }}</span>
          <button
            class="submit-amount"
            :class="{ isDisabled: list.isOverdue }"
            :disabled="list.isOverdue == 1 ? true : false"
            @click="submit"
          >
            提交订单
          </button>
        </div>
      </div>
    </div>
    <div v-if="list.isOverdue == 0">
      <van-calendar
        :default-date="defaultDate"
        v-model="show"
        :min-date="minDate"
        :max-date="maxDate"
        type="range"
        @confirm="onConfirm"
      />
    </div>
    <!-- <div v-else>
      <van-calendar
        :default-date="defaultDate"
        v-model="show"
        type="range"
        @confirm="onConfirm"
      />
    </div>-->
  </head-top>
</template>

<script>
import headTop from "../../components/head.vue";
import { getDays } from "../../utils/utils.js";
import { listAll, commit } from "../../api/home/index.js";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      minDate: "",
      maxDate: "",
      defaultDate: [],
      value: "",
      houseList: [], //房型列表数据
      packageList: [
        {
          value: 0,
          urlImg: require("../../assets/zuhe.png"),
          title: "自助早餐",
          price: "￥42",
          amount: 42,
          newPrice: "￥48",
        },
        {
          value: 0,
          urlImg: require("../../assets/zuhe.png"),
          title: "自助午餐",
          price: "￥174",
          amount: 174,
          newPrice: "￥198",
        },
        {
          value: 0,
          urlImg: require("../../assets/zuhe.png"),
          title: "自助晚餐",
          price: "￥262",
          amount: 262,
          newPrice: "￥298",
        },
      ], //组合套餐
      startDate: "", //开始日期
      endDate: "", //结束日期
      show: false,
      images: [],
      serviceList: [
        {
          icom: "location-o",
          content:
            " 地址：广州市白云区白云大道北2号广外国际学术交流中心(广东外语外贸大学国际学院侧)",
        },
        {
          icom: "coupon-o",
          content: "邮编：510420",
        },
        {
          icom: "phone-o",
          content: "电话：86-020-66618886",
        },
        {
          icom: "envelop-o",
          content: "邮箱：rsvn@easelandhotel.com",
        },
        {
          icom: "friends-o",
          content: "公众号：广州逸林假日酒店",
        },
      ],
      facilitiesList: [
        {
          urlImg: require("../../assets/jicun.png"),
          name: "行李寄存",
        },
        {
          urlImg: require("../../assets/kuandai.png"),
          name: "宽带",
        },
        {
          urlImg: require("../../assets/guding.png"),
          name: "电话",
        },
        {
          urlImg: require("../../assets/youxian.png"),
          name: "有线电视",
        },
        {
          urlImg: require("../../assets/reshui.png"),
          name: "热水",
        },
        {
          urlImg: require("../../assets/jianshen.png"),
          name: "健身室",
        },
        {
          urlImg: require("../../assets/tingche.png"),
          name: "停车场",
        },
        {
          urlImg: require("../../assets/huiyi.png"),
          name: "会议室",
        },
        {
          urlImg: require("../../assets/zuche.png"),
          name: "租车",
        },
        {
          urlImg: require("../../assets/youyong.png"),
          name: "游泳池",
        },
        {
          urlImg: require("../../assets/baoxian.png"),
          name: "保险箱",
        },
        {
          urlImg: require("../../assets/naozhong.png"),
          name: "叫醒",
        },
        {
          urlImg: require("../../assets/kongtiao.png"),
          name: "空调",
        },
        {
          urlImg: require("../../assets/canyin.png"),
          name: "餐饮",
        },
        {
          urlImg: require("../../assets/zaocan.png"),
          name: "早餐",
        },
      ],
      isIntroduce: true,
      isFacilities: true,
      amountTotal: 0,
      list: {
        bookingInstructions: [],
      },
      form: {
        activityId: "",
        name: "", //预定人姓名
        phone: "", //手机号
        checkTime: "", //入住时间
        leaveTime: "", //离开时间
        remark: "", //备注
        company: "", //公司名称
        orderRooms: [], //房间数量和类型
        orderRoomPeoples: [], //房间用户信息
      },
      listType: [], //房间类型数据
      evening: 1,
      tian: "0",
      shi: "00",
      fen: "00",
      miao: "00",
      timer: "",
    };
  },
  components: {
    headTop,
  },
  watch: {
    evening() {
      let amountTotal = 0;
      this.houseList.forEach((item) => {
        amountTotal = amountTotal + (item.roomPrice / 100) * item.value;
      });
      this.amountTotal = (amountTotal * parseInt(this.evening)).toFixed(2);
    },
    houseList() {
      console.log(88888);
    },
  },
  created() {
    this.listAll();
    if (this.$route.params.successful) {
      this.$toast.success("报名成功");
    }
  },
  methods: {
    clickChecked(index, i) {
      this.houseList[index].orderRoomPeoples[i].checked =
        !this.houseList[index].orderRoomPeoples[i].checked;
    },
    inputName() {
      let flag = true;
      this.houseList.forEach((item) => {
        if (item.orderRoomPeoples.length > 0 && flag) {
          item.orderRoomPeoples[0].name = item.orderRoomPeoples[0].name
            ? item.orderRoomPeoples[0].name
            : this.form.name;
          flag = false;
        }
      });
    },
    inputPhone() {
      let flag = true;
      this.houseList.forEach((item) => {
        if (item.orderRoomPeoples.length > 0 && flag) {
          item.orderRoomPeoples[0].phone = item.orderRoomPeoples[0].phone
            ? item.orderRoomPeoples[0].phone
            : this.form.phone;
          flag = false;
        }
      });
    },
    zhuanfen(roomPrice) {
      return roomPrice / 100;
    },
    // 查看图片
    clickImg(item) {
      // console.log(item);
      let arr = [];
      item.roomPicList.forEach((item) => {
        arr.push(item.path);
      });
      // console.log(arr, "arrarr");
      ImagePreview(arr);
    },
    countDown() {
      let d = parseInt(this.list.overdueTime / (24 * 60 * 60));
      d = d < 10 ? "0" + d : d;
      let h = parseInt((this.list.overdueTime / (60 * 60)) % 24);
      h = h < 10 ? "0" + h : h;
      let m = parseInt((this.list.overdueTime / 60) % 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(this.list.overdueTime % 60);
      if (s <= 0) {
        s = 0;
      }
      s = s < 10 ? "0" + s : s;
      this.tian = d;
      this.shi = h;
      this.fen = m;
      this.miao = s;
      // console.log(this.tian, this.shi, this.fen, this.miao, "YYYYYYHHHHHg");
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        this.list.overdueTime -= 1;
        this.countDown();
      }, 1000);
    },

    // 点击到订单中心
    orderClcik() {
      this.$router.push({
        name: "payList",
      });
    },
    // 获取列表数据
    async listAll() {
      let data = {
        activityId: this.$route.query.activityId,
      };
      let res = await listAll(data);
      if (res.code == 200) {
        console.log(
          res.data.bookingInstructions,
          "res.data.bookingInstructionsres.data.bookingInstructions"
        );
        if (res.data.bookingInstructions) {
          res.data.bookingInstructions = res.data.bookingInstructions.replace(
            "\n",
            "||"
          );
          res.data.bookingInstructions = res.data.bookingInstructions.replace(
            "\r",
            "||"
          );
          res.data.bookingInstructions = res.data.bookingInstructions.replace(
            "\r\n",
            "||"
          );
          res.data.bookingInstructions =
            res.data.bookingInstructions.split("||");
        }
        this.list = res.data;
        res.data.roomList.forEach((item) => {
          item.orderRoomPeoples = [];
          this.listType.push(item);
          item.value = 0;
          item.roomPicList.forEach((v) => {
            v.path = window.location.origin + v.path;
          });
        });
        res.data.hotelPicList.forEach((item) => {
          item.path = window.location.origin + item.path;
        });
        this.images = res.data.hotelPicList;
        this.form.checkTime = this.list.entryTime;
        this.form.leaveTime = this.list.leaveTime;

        if (this.list.isOverdue == 0) {
          this.defaultDate = [
            new Date(this.list.entryTime),
            new Date(this.list.leaveTime),
          ];
          this.minDate = new Date(this.list.activityTime);
          this.maxDate = new Date(this.list.effectiveTime);
          this.startDate = this.formatDate(new Date(this.list.entryTime));
          this.endDate = this.formatDate(new Date(this.list.leaveTime));
          this.evening = getDays(this.startDate, this.endDate);
          this.Time();
        }

        this.houseList = res.data.roomList;
      }
    },
    // 加
    plusItem() {
      this.form.orderRooms = [];
      let amountTotal = 0;
      this.houseList.forEach((item) => {
        console.log(item.value, "valuevalue");
        let list = {
          roomId: item.id,
          num: item.value,
        };
        let listItem = {
          roomId: item.id,
          name: "",
          phone: "",
        };

        if (item.value > 0) {
          console.log(item, "itemitem");
          this.form.orderRooms.push(list);
          item.orderRoomPeoples.push(listItem);
        }
        // amountTotal = amountTotal + (item.roomPrice / 100) * item.value;
      });
      // this.amountTotal = (amountTotal * parseInt(this.evening)).toFixed(2);
      console.log(this.form.orderRooms);
    },
    // 数量加减
    changeItem(val) {
      console.log(val, 'val')
      let index = 0;
      this.form.orderRooms = [];
      let amountTotal = 0;
      this.houseList.forEach((item) => {
        console.log(item.value, "item.valueitem.value");
        let list = {
          roomId: item.id,
          num: item.value,
        };
        if (item.value > 0) {
          this.form.orderRooms.push(list);
        }
        // item.orderRoomPeoples = [];
        amountTotal = amountTotal + (item.roomPrice / 100) * item.value;
        let orderLen = item.orderRoomPeoples.length
        if(item.orderRoomPeoples.length) {
          let num = Number(item.value) || 0
           
          if(num < orderLen) {
            // 获取到有数据的列表
            let fList =  item.orderRoomPeoples.filter(val => val.name || val.phone)
            
            // 如果有数据的列表超过了设置的值就裁剪
            if(fList.length > num) {
              item.orderRoomPeoples = fList.slice(0, num)
            } else {
              // 需要创建的数据量
              let cNum = num - fList.length
             
              if(cNum > 0) {
                for (let index = 0; index < cNum; index++) {
                  fList.push({
                    checked: false,
                    roomId: item.id,
                    name: "",
                    phone: "",
                  }) 
                }
              }
              item.orderRoomPeoples = fList
            }
          } else {
            let cNum = num - orderLen
            if(cNum > 0) {
              for (let index = 0; index < cNum; index++) {
                item.orderRoomPeoples.push({
                  checked: false,
                  roomId: item.id,
                  name: "",
                  phone: "",
                }) 
              }
            } 
          }
          
        } else {
          for (let i = 0; i < item.value; i++) {
            let list = {
              checked: false,
              roomId: item.id,
              name: index == 0 ? this.form.name : "",
              phone: index == 0 ? this.form.phone : "",
            };
            item.orderRoomPeoples.push(list);
            index++;
          }
          console.log(item.orderRoomPeoples, 55555)
        }
        console.log(item, 'item')
      });
      this.amountTotal = (amountTotal * parseInt(this.evening)).toFixed(2);
      console.log(this.houseList, " this.houseList");
    },
    // 服务设施展开
    clickFacilities() {
      this.isFacilities = !this.isFacilities;
    },
    // 酒店介绍展开
    clickIntroduce() {
      this.isIntroduce = !this.isIntroduce;
    },
    clickDate() {
      this.show = true;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      // console.log(date);
      const [start, end] = date;
      this.show = false;
      this.form.checkTime = start.getTime();
      this.form.leaveTime = end.getTime();
      this.startDate = this.formatDate(start);
      this.endDate = this.formatDate(end);
      this.evening = getDays(this.startDate, this.endDate);
    },
    // 提交订单
    async submit() {
      var plate =
        /^((13[0-9])|(14[56789])|(15[^4])|(16[56])|(17[^9])|(18[0-9])|(19[1589]))\d{8}$/;
      if (!this.startDate) {
        this.$toast("请填写入住时间");
        return;
      }
      if (!this.form.name) {
        this.$toast("请填写预订人姓名");
        return;
      }
      if (!this.form.phone) {
        this.$toast("请填写预订人手机号");
        return;
      }
      if (!plate.test(this.form.phone)) {
        this.$toast("预订人手机号有误");
        return;
      }
      let flag = false;
      let arr = [];

      // console.log(this.houseList, "--");
      this.houseList.forEach((item) => {
        if (item.orderRoomPeoples.length > 0) {
          for (var i = 0; i < item.orderRoomPeoples.length; i++) {
            arr.push(item.orderRoomPeoples[i]);
            if (!item.orderRoomPeoples[i].name) {
              this.$toast(`${item.roomType}房间${i + 1}请填写入住姓名`);
              flag = true;
              break;
            }
            if (!item.orderRoomPeoples[i].phone) {
              this.$toast(`${item.roomType}房间${i + 1}请填写入住手机`);
              flag = true;
              break;
            }
            if (!plate.test(item.orderRoomPeoples[i].phone)) {
              this.$toast(`${item.roomType}房间${i + 1}手机号输入有误`);
              flag = true;
              break;
            }
          }
        }
      });
      if (arr.length == 0) {
        this.$toast("至少选择一间房");
        return;
      }
      // console.log(arr, "arr");
      if (flag) {
        return;
      }

      this.form.orderRoomPeoples = arr;
      this.form.activityId = this.list.id;
      this.form.sumPrice = parseInt(this.amountTotal * 100);
      let res = await commit(this.form);
      if (res.code == 200) {
        this.$router.push({
          name: "pay",
          query: {
            orderId: res.data.orderId,
            amountTotal: this.form.sumPrice,
            remainSec: res.data.remainSec,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  padding-bottom: 100px;
  position: relative;
  .head-img {
    height: 450px;
    img {
      width: 100%;
      height: 450px;
    }
  }
  .list-date {
    padding-left: 30px;
    height: 68px;
    background: rgba(226, 100, 138, 1);
    position: relative;
    padding-left: 290px;
    .date-left {
      line-height: 68px;
      width: 250px;
      font-size: 28px;
      color: rgba(255, 229, 0, 1);
      position: absolute;
      left: 30px;
    }
    .date-right {
      height: 100%;
      position: relative;
      text-align: right;
      padding-right: 10px;
      padding-top: 2px;
      padding-left: 180px;
      line-height: 68px;
      padding-top: 5px;
      .title {
        font-size: 24px;
        display: inline-block;
        position: absolute;
        left: 0;
        width: 180px;
      }
      .date-remaining {
        // display: inline-block;

        flex: 1;
        width: 250px;
        background-color: #fff;
        height: 56px;
        border-radius: 28px;
        text-align: left;
        padding-left: 5px;
        line-height: 56px;
        position: relative;
        .tian {
          width: 80px;
          position: absolute;
          top: 16px;
          font-size: 30px;
          line-height: 28px;
          text-align: right;
          color: rgba(226, 100, 138, 1);
          margin-right: 10px;
        }
        .shi {
          position: absolute;
          top: 8px;
          left: 90px;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 28px;
          text-align: center;
          border-radius: 4px;
          background: rgba(226, 100, 138, 1);
          color: #fff;
          margin-right: 10px;
        }
        .fen {
          position: absolute;
          top: 8px;
          left: 140px;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 28px;
          text-align: center;
          border-radius: 4px;
          background: rgba(226, 100, 138, 1);
          color: #fff;
          margin-right: 10px;
        }
        .miao {
          position: absolute;
          top: 8px;
          left: 190px;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 28px;
          text-align: center;
          border-radius: 4px;
          background: rgba(226, 100, 138, 1);
          color: #fff;
        }
        // font-size: 20px;
        // line-height: 56px;
      }
    }
  }
  .list-item {
    .list-stay {
      padding: 0 10px;
      margin: 30px 0;
      display: flex;
      .list-ru,
      .list-li {
        position: relative;
        height: 60px;
        flex: 1;
        font-size: 28px;
        line-height: 60px;
        text-align: center;
        border-radius: 10px;
        border: 1px solid rgba(166, 166, 166, 1);
        padding-right: 44px;
      }
      ::v-deep.van-icon {
        font-size: 44px !important;
        position: absolute;
        right: 5px;
        top: 6px;
      }
      .list-wan {
        height: 60px;
        margin: 0 20px;
        width: 160px;
        border: 1px solid rgba(255, 87, 51, 1);
        box-shadow: 2px 2px 5px 3px #ff5733 !important;
        color: rgba(255, 87, 51, 1);
        border-radius: 30px;
        line-height: 60px;
        font-size: 28px;
        text-align: center;
      }
    }
    .room {
      display: flex;
      div {
        flex: 1;
      }
      .room-left {
        padding-left: 20px;
        font-size: 28px;
        font-weight: 600;
      }
      .room-right {
        text-align: right;
        position: fixed;
        right: 0;
        z-index: 999;
        width: 100px;
        .right-content {
          width: 130px;
          background-color: rgba(61, 67, 67, 1);
          height: 42px;
          border-radius: 18px 0 0 18px;
          position: absolute;
          right: 0;
          font-size: 24px;
          color: rgba(164, 140, 108, 1);
          text-align: center;
          line-height: 42px;
        }
      }
    }
    .house,
    .package-item {
      .hotel-item {
        margin: 20px 0;
        padding: 0 20px;
        margin-bottom: 40px;
        .item {
          border-radius: 6px;
          box-shadow: 0 0 12px #dcdcdc;
          padding: 20px;
          // height: 160px;
          background: #fff;
          border: 1px solid #f1f1f1;
          padding-left: 240px;
          position: relative;
          .item-left {
            width: 180px;
            height: 150px;
            display: inline-block;
            position: absolute;
            left: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .item-right {
            width: 100%;
            .one {
              padding-right: 12px;
              height: 40px;
              line-height: 40px;
              font-weight: 600;
              .title {
                font-size: 28px;
                float: left;
              }
              .price {
                float: right;
                font-size: 36px;
                color: rgba(245, 106, 64, 1);
              }
            }
            .tow {
              margin-top: 10px;
              height: 40px;
              line-height: 40px;
              padding-right: 18px;
              .title {
                max-width: 60%;
                font-size: 24px;
                float: left;
                color: rgba(152, 146, 146, 1);
              }
              .price {
                float: right;
                font-size: 28px;
                color: rgba(152, 146, 146, 1);
                text-decoration: line-through;
              }
            }
            .three {
              width: 100%;
              margin-top: 25px;
              overflow: hidden;
              .number {
                font-size: 26px;
                font-weight: 600;
                float: left;
              }
              .step {
                float: right;
              }
            }
          }
        }
      }
    }
    .package {
      padding: 0 20px;
      .combination {
        height: 60px;
        padding-left: 5px;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 7px rgba(0, 0, 0, 0.2);
        font-size: 28px;
        font-weight: 600;
        line-height: 60px;
        border-bottom: 1px solid #f1f1f1;
      }
    }
    .introduce {
      padding: 0 20px;
      .introduce-top {
        display: flex;
        background: #fff;
        height: 60px;
        line-height: 60px;
        padding-left: 5px;
        border-bottom: 1px solid #f1f1f1;
        .introduce-left {
          font-size: 30px;
          padding-left: 5px;
          flex: 1;
          text-align: left;
          font-weight: 600;
        }
        .introduce-right {
          flex: 1;
          padding-right: 20px;
          text-align: right;
          font-size: 30px;
        }
      }
      .introduce-content {
        padding: 20px;
        background: #fff;
        .introduce-wenben {
          font-size: 24px;
          color: rgba(104, 107, 112, 1);
        }
        .service {
          margin-top: 40px;
          position: relative;
          padding-left: 70px;
          .service-icom {
            width: 60px;
            font-size: 32px;
            color: rgb(110, 108, 108);
            position: absolute;
            left: 0;
          }
          .service-content {
            font-size: 24px;
            color: rgb(71, 71, 71);
          }
        }
      }
    }
    .facilities {
      margin-top: 20px;
      padding: 0 20px;
      .introduce-top {
        display: flex;
        background: #fff;
        height: 60px;
        line-height: 60px;
        padding-left: 5px;
        border-bottom: 1px solid #f1f1f1;
        .introduce-left {
          font-size: 30px;
          padding-left: 5px;
          flex: 1;
          text-align: left;
          font-weight: 600;
        }
        .introduce-right {
          flex: 1;
          padding-right: 20px;
          text-align: right;
          font-size: 30px;
        }
      }
      .introduce-content {
        background-color: #fff;
        .facilities-item {
          width: 25%;
          display: inline-block;
          .facilities-icom {
            height: 120px;
            text-align: center;
            padding: 10px;
            margin: 20px 0;
            img {
              width: 50px;
              height: 50px;
              display: inline-block;
            }
            div {
              font-size: 26px;
              color: #333;
            }
          }
        }
      }
    }
    .reservation {
      margin-top: 20px;
      padding: 0 20px;
      .introduce-top {
        display: flex;
        background: #fff;
        height: 60px;
        line-height: 60px;
        padding-left: 5px;
        border-bottom: 1px solid #f1f1f1;
        .introduce-left {
          font-size: 30px;
          padding-left: 5px;
          flex: 1;
          text-align: left;
          font-weight: 600;
        }
        .introduce-right {
          flex: 1;
          padding-right: 20px;
          text-align: right;
          font-size: 30px;
        }
      }
      .reservation-content {
        .title {
          font-size: 30px;
          margin-top: 20px;
          padding: 10px 10px;
          margin-top: 10px;
        }
        .reservation-input {
          position: relative;
          background-color: #fff;
          margin-top: 10px;
          display: flex;
          align-items: baseline;
          // padding: 10px 0;
          span {
            font-size: 28px;
            position: absolute;
            left: 20px;
            font-weight: 600;
          }
          input {
            font-size: 28px;
          }
          ::v-deep.van-field__label {
            width: 150px;
          }
          ::v-deep.van-cell {
            font-size: 26px;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 10px;
          }
        }
        .reservation-zidingyi {
          padding: 0px 10px;
          display: flex;
          line-height: 60px;
          .div-left {
            text-align: left;
            width: 150px;
            color: #646566;
            font-size: 28px;
          }
          .div-right {
            flex: 1;
            padding: 0px 20px;
            // line-height: 1.5;
            padding-right: 160px;
            position: relative;
            input {
              width: 100%;
              border: none;
              font-size: 28px;
            }
            .i1 {
              width: 40px;
              height: 40px;
              display: inline-block;
              background-image: url("../../assets/touxiang.png");
              background-size: cover;
              position: absolute;
              right: 20px;
              top: 10px;
            }
            .i2 {
              width: 40px;
              height: 40px;
              display: inline-block;
              background-image: url("../../assets/dianhua.png");
              background-size: cover;
              position: absolute;
              right: 20px;
              top: 10px;
            }
          }
        }
        .reservation-input-one {
          margin-top: 0px;
        }
      }
      .reservation-content-one {
        background: #fff !important;
        .reservation-content-type {
          padding: 10px 0;
          .fangjian {
            display: flex;
            align-items: baseline;
            border-bottom: 1px solid #ccc;
            .reservation-p {
              flex: 1;
              font-size: 26px;
              padding: 10px 10px;
            }
            .div-right {
              flex: 1;
              text-align: right;
              padding-right: 10px;
              font-size: 24px;
              color: #1890ff;
              span {
                width: 200px;
                display: inline-block;
                height: 100%;
              }
            }
          }
        }
      }
    }
    .instructions {
      margin-top: 20px;
      padding: 0 20px;
      padding-top: 40px;
      padding-bottom: 40px;
      .instructions-item {
        padding: 20px;
        background: #fff;
        font-weight: 600;
        .instructions-title {
          font-size: 30px;
          margin-bottom: 30px;
        }
        .instructions-content {
          div {
            font-size: 26px;
            color: rgb(127, 127, 127);
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .submit {
    border-top: 1px solid #f1f1f1;
    width: 100%;
    height: 100px;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    line-height: 100px;
    .real-pay {
      height: 100%;
      padding-left: 20px;
      text-align: left;
      position: relative;
      line-height: 80px;
      .span1 {
        font-size: 30px;
      }
      .span2 {
        font-size: 32px;
        color: rgb(234, 82, 53);
      }
      .submit-amount {
        height: 70px;
        width: 180px;
        display: inline-block;
        background-color: rgb(234, 82, 53);
        color: #fff;
        line-height: 70px;
        text-align: center;
        font-size: 28px;
        border-radius: 60px;
        position: absolute;
        right: 18px;
        top: 15px;
        border: none;
      }
      .isDisabled {
        background: #ccc;
      }
    }
  }
}
</style>
<style lang="scss">
input::-webkit-input-placeholder {
  color: rgba(150, 151, 153, 0.6);
  font-size: 26px;
}
input::-moz-placeholder {
  color: rgba(150, 151, 153, 0.6);
  font-size: 26px;
}
input:-ms-input-placeholder {
  color: rgba(150, 151, 153, 0.6);
  font-size: 26px;
}
// .list-content {
//   .headerTab {
//     .title {
//       text-shadow: 0 0.036rem 0.036rem rgb(0, 0, 0 / 50%);
//     }
//   }
// }
</style>